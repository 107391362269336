import React, { useRef, useState, useEffect } from 'react';

const avatars = [
  { src: '/avatars/dom.jpg', name: '9dom9' },
  { src: '/avatars/jeslime.jpg', name: 'Jeslime' },
  { src: '/avatars/loo.jpg', name: 'veniloo' },
  { src: '/avatars/red.jpg', name: 'RedPlashe' },
  { src: '/avatars/timer.jpg', name: '69timer69' },
  { src: '/avatars/mrweez.jpg', name: 'MrWeez' },
  { src: '/avatars/csdfik.png', name: 'csdfik' },
  { src: '/avatars/rizzb1t.png', name: 'Rizzb1t' },
];

export default function HoverScrollAvatarCarousel({ isModalOpen }) {
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [touchStartX, setTouchStartX] = useState(null);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 640);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const scrollToAvatar = (index) => {
    const avatarWidth = carouselRef.current?.offsetWidth / 3; // Set as one-third of the visible width
    carouselRef.current.scrollTo({
      left: avatarWidth * index,
      behavior: 'smooth',
    });
  };

  const handleScroll = (event) => {
    if (isModalOpen || !carouselRef.current) return;

    event.preventDefault();
    const direction = event.deltaY > 0 ? 1 : -1;
    const newIndex = (currentIndex + direction + avatars.length) % avatars.length;
    setCurrentIndex(newIndex);
    scrollToAvatar(newIndex);
  };

  const handleTouchStart = (event) => {
    setTouchStartX(event.touches[0].clientX);
  };

  const handleTouchMove = (event) => {
    if (touchStartX === null) return;

    const touchCurrentX = event.touches[0].clientX;
    const diffX = touchStartX - touchCurrentX;

    if (Math.abs(diffX) > 30) {
      const newIndex = diffX > 0
        ? (currentIndex + 1) % avatars.length // Swipe left
        : (currentIndex - 1 + avatars.length) % avatars.length; // Swipe right

      setCurrentIndex(newIndex);
      scrollToAvatar(newIndex);
      setTouchStartX(null); // Reset touch start
    }
  };

  useEffect(() => {
    const carouselElement = carouselRef.current;
    if (carouselElement) {
      carouselElement.addEventListener('wheel', handleScroll, { passive: false });
      carouselElement.addEventListener('touchstart', handleTouchStart, { passive: false });
      carouselElement.addEventListener('touchmove', handleTouchMove, { passive: false });
    }
    return () => {
      if (carouselElement) {
        carouselElement.removeEventListener('wheel', handleScroll);
        carouselElement.removeEventListener('touchstart', handleTouchStart);
        carouselElement.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, [currentIndex]);

  const displayedAvatars = isMobile
    ? [
        avatars[(currentIndex - 1 + avatars.length) % avatars.length],
        avatars[currentIndex],
        avatars[(currentIndex + 1) % avatars.length],
      ]
    : avatars;

  const avatarSize = isMobile ? 'w-16 h-16' : 'w-24 h-24';
  const smallAvatarSize = isMobile ? 'w-12 h-12' : 'w-16 h-16';

  return (
    <div className="flex flex-col items-center w-full h-64">
      <div
        ref={carouselRef}
        className="flex justify-center items-center gap-4 w-full flex-grow overflow-x-auto"
        style={{
          whiteSpace: 'nowrap',
          scrollBehavior: 'smooth',
        }}
      >
        {displayedAvatars.map((avatar, index) => {
          const isCenter = isMobile ? index === 1 : index === currentIndex;
          const size = isCenter ? avatarSize : smallAvatarSize;
          const opacity = isCenter ? 'opacity-100' : 'opacity-50';
          const zIndex = isCenter ? 'z-10' : 'z-0';

          return (
            <div
              key={index}
              className={`${size} ${opacity} ${zIndex} transition-all duration-300`}
            >
              <img
                src={avatar.src}
                alt={avatar.name}
                className="rounded-full shadow-md"
              />
            </div>
          );
        })}
      </div>
      <div className="text-center text-white font-bold mb-12 text-lg animate-fade-in ">
        {avatars[currentIndex]?.name}
      </div>
    </div>
  );
}
