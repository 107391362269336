import React from 'react';

const SocialButtons = () => {
  const socialLinks = [
    { icon: '/social/steam.png', link: 'https://steamcommunity.com/id/thezhake78/' },
    { icon: '/social/discord.png', link: 'https://discord.gg/q9Rd8QFVHD' },
    { icon: '/social/twitch.png', link: 'https://www.twitch.tv/manukq_' },
    { icon: '/social/telegram.png', link: 'https://t.me/manukqq' },
    { icon: '/social/youtube.png', link: 'https://www.youtube.com/@narezkimanukq?sttick=0' },
    { icon: '/social/github.png', link: 'https://github.com/manukek' },
  ];

  return (
    <div className="flex justify-center space-x-4 mt-8">
      {socialLinks.map((social, index) => (
        <a
          key={index}
          href={social.link}
          target="_blank"
          rel="noopener noreferrer"
          className="w-10 h-10 bg-gray-700 rounded-full flex items-center justify-center opacity-80 hover:opacity-100 transition duration-200"
        >
          <img src={social.icon} className="w-6 h-6" alt={`Icon for ${social.link}`} />
        </a>
      ))}
    </div>
  );
};

export default SocialButtons;