import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`flex flex-col items-center font-sans text-white p-3 fixed top-0 left-0 right-0 z-50 backdrop-blur-sm bg-opacity-30 transition-colors duration-400 ${scrolled ? 'bg-[#1c2d33]' : 'bg-[#21343B]'}`}>
      <div className="w-full flex justify-between items-center p-2">
        <div className="flex items-center space-x-2">
          <img
            alt="manukq"
            src="https://kappa.lol/5reXH"
            className="inline-block h-10 w-10 rounded-lg"
          />
        </div>
        <div className="text-lg flex -space-x-1 font-extrabold font-manrope">
          Manukq
        </div>
        <ul className="flex space-x-4">
            <li><Link to="/404" className="hover:text-gray-400 transition duration-300 ease-in-out">Блог</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;