import React from "react";
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { HomeIcon } from '@heroicons/react/24/solid';

const PageNotFound = () => {
    return (
        <div className="m-10 relative flex flex-col items-center text-white text-7xl {{ width: '1000px', height: '1000px' }}">
            <h1>404 Error</h1>
            <h1 className="p-5 text-2xl">Этой страницы не существует, либо она на доработке.</h1>
            <div className="font-inter drop-shadow-xl shadow-white">
                <Link to="/" className="hover:text-gray-400">
                    <HomeIcon className="w-9 h-9"/>
                </Link>
            </div> 
        </div>
    );
};
 
export default PageNotFound;